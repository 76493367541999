.op-mb-0 {
	margin-bottom: 0px;
}

.op-mb-3 {
	margin-bottom: 3px;
}

.op-mb-4 {
	margin-bottom: 4px;
}

.op-mt-4 {
	margin-top: 4px;
}

.op-mtb-4 {
	margin-top: 4px;
	margin-bottom: 4px;
}

.op-mb-5 {
	margin-bottom: 5px;
}

.op-mt-5 {
	margin-top: 5px;
}

.op-ml-5 {
	margin-left: 5px;
}

.op-mr-5 {
	margin-right: 5px;
}

.op-mtb-6 {
	margin-top: 6px;
	margin-bottom: 6px;
}

.op-mb-8 {
	margin-bottom: 8px;
}

.op-mt-8 {
	margin-top: 8px;
}

.op-mtb-8 {
	margin-top: 8px;
	margin-bottom: 8px;
}

.op-ptb-8 {
	padding-top: 8px;
	padding-bottom: 8px;
}

.op-mb-10 {
	margin-bottom: 10px;
}

.op-mt-10 {
	margin-top: 10px;
}

.op-mtb-10 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.op-ml-10 {
	margin-left: 10px;
}

.op-mr-10 {
	margin-right: 10px;
}

.op-mb-12 {
	margin-bottom: 12px;
}

.op-mt-12 {
	margin-top: 12px;
}

.op-mtb-12 {
	margin-top: 12px;
	margin-bottom: 12px;
}

.op-mb-16 {
	margin-bottom: 16px;
}

.op-mt-16 {
	margin-top: 16px;
}

.op-mtb-16 {
	margin-top: 16px;
	margin-bottom: 16px;
}

.op-mb-20 {
	margin-bottom: 20px;
}


.op-mt-20 {
	margin-top: 20px;
}

.op-mtb-20 {
	margin-top: 20px;
	margin-bottom: 20px;
}

.op-ml-20 {
	margin-left: 20px;
}

.op-mr-20 {
	margin-right: 20px;
}

.op-pad-20 {
	padding: 20px;
}

.op-mb-30 {
	margin-bottom: 30px;
}

.op-mt-30 {
	margin-top: 30px;
}

.op-mtb-30 {
	margin-top: 30px;
	margin-bottom: 30px;
}

.op-mb-32 {
	margin-bottom: 32px;
}

.op-mt-32 {
	margin-top: 32px;
}

.op-mtb-32 {
	margin-top: 32px;
	margin-bottom: 32px;
}

.op-mb-40 {
	margin-bottom: 40px;
}

.op-mt-40 {
	margin-top: 40px;
}

.op-mtb-40 {
	margin-top: 40px;
	margin-bottom: 40px;
}

.op-pad-16 {
  padding: 16px;
}


.op-pt-40 {
	padding-top: 40px;
}

.op-pb-40 {
	padding-bottom: 40px;
}

.op-mt-50 {
	margin-top: 50px;
}

.op-green-divider {
	padding-top: 15px;
	border-bottom: 3px solid;
	border-color: $op-green;
}

.op-center {
	text-align: center;
}

.op-left {
	text-align: left;
}

.op-right {
	text-align: right;
}

.op-full-width {
	width: 100%;
}

.op-button-width {
	max-width: 325px;
}

.op-display-block {
	display: block;
}
