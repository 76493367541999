.op-form-group {

	text-align: left;
	margin-bottom: 20px;
	mat-radio-group {
		max-width: 70%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 15px;
	}

	.op-form-title {
		@extend .op-body-1-bold-font;
		color: $op-charcoal;
		margin-bottom: 20px;
	}

	.op-form-info {
		@extend .op-body-2-font;
		color: $op-s-gray;
		margin-bottom: 20px;
	}
}

.op-input-field {
	.op-hint {
		@extend .op-label-font;
	}
	.op-error {
		@extend .op-label-font;
	}
}

// fix bottom margin issues with form hint and error overlaying next control
// https://github.com/angular/components/issues/5227
form {
	.mat-form-field {
		.mat-form-field-wrapper {
			padding-bottom: 0;

			.mat-form-field-underline {
				position: initial !important;
				display: block;
				margin-top: -1px;
			}

			.mat-form-field-subscript-wrapper,
			.mat-form-field-ripple {
				position: initial !important;
				display: table;
			}

			.mat-form-field-subscript-wrapper {
				min-height: calc(1rem + 1px);
			}
		}
	}
}
