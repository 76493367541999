/* You can add global styles to this file, and also import other style files */

@import './variables.scss';
@import './oportun-theme.scss';
@import './fonts.scss';
@import './forms.scss';
@import './buttons.scss';
@import './inputs.scss';
@import './spacing.scss';
@import './colors.scss';

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

@font-face {
	font-family: 'TT-Commons-Pro';
	src: url(../assets/fonts/TT_Commons_Pro_Normal.otf) format('opentype'),
		url(../assets/fonts/TT_Commons_Pro_Normal.woff) format('woff'),
		url(../assets/fonts/TT_Commons_Pro_Normal.woff2) format('woff2'),
		url(../assets/fonts/TT_Commons_Pro_Normal.ttf) format('truetype'), url(../assets/fonts/TT_Commons_Pro_Normal.eot);
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'TT-Commons-Pro';
	src: url(../assets/fonts/TT_Commons_Pro_Bold.otf) format('opentype'),
		url(../assets/fonts/TT_Commons_Pro_Bold.woff) format('woff'),
		url(../assets/fonts/TT_Commons_Pro_Bold.woff2) format('woff2'),
		url(../assets/fonts/TT_Commons_Pro_Bold.ttf) format('truetype'), url(../assets/fonts/TT_Commons_Pro_Bold.eot);
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'TT-Commons-Pro';
	src: url(../assets/fonts/TT_Commons_Pro_DemiBold.otf) format('opentype'),
		url(../assets/fonts/TT_Commons_Pro_DemiBold.woff) format('woff2'),
		url(../assets/fonts/TT_Commons_Pro_DemiBold.woff2) format('woff2'),
		url(../assets/fonts/TT_Commons_Pro_DemiBold.ttf) format('truetype'),
		url(../assets/fonts/TT_Commons_Pro_DemiBold.eot);
	font-weight: 600;
	font-style: normal;
}

.pac-container:after {
	/* remove power by google from autocomplete */
	background-image: none !important;
	height: 0;
}

// innerHtml styling needs to be in the global space.
.loan-disclosures {
	li {
		margin-bottom: 0.625rem;
	}
}

//Overriding padding for mat-dialog
.mat-dialog-container {
	padding: $op-mat-dialog-padding !important;
	max-width: 500px;
	.mat-dialog-content {
		margin-right: 0;
	}
	.mat-dialog-actions {
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

.terms-confirmed {
	.mat-tab-label {

		padding: 0 5px !important;
	}
	.mat-tab-label-active {
		@extend .op-black;
	}
}
