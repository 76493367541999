@use '@angular/material' as mat;


.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
	color: $op-s-gray;
}

.mat-form-field.mat-focused .mat-form-field-label .mat-form-field-required-marker,
.mat-form-field.mat-focused .mat-form-field-label {
	@extend .op-label;
	color: $op-s-gray;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker,
.mat-form-field.mat-form-field-invalid .mat-select-value,
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
	color: $op-red;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
	color: $op-s-gray;
}

.mat-input-element {
	caret-color: $op-s-gray;
}

.mat-radio-outer-circle {
	border: 1px solid $op-dark-gray !important;
}

.mat-checkbox-checkmark-path {
	stroke: $op-primary-black !important;
}

.mat-checkbox-background, .mat-checkbox-frame {
	border-radius: 4px !important;
	border: 1px solid $op-medium-grey !important;
}

.mat-checkbox-persistent-ripple {
	.mat-checkbox.cdk-focused & { opacity: 0.12; }
}
