@use '@angular/material' as mat;
@use 'sass:map';
@import 'variables.scss';

// text wrap for mat-checkbox
.mat-checkbox-layout {
	white-space: normal !important;
}

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// mat-green
// 50#E8F5E9
// 100#C8E6C9
// 200#A5D6A7
// 300#81C784
// 400#66BB6A
// 500#4CAF50
// 600#43A047
// 700#388E3C
// 800#2E7D32
// 900#1B5E20
// A100#B9F6CA
// A200#69F0AE
// A400#00E676
// A700#00C853

$my-primary: (
	50: #e0f1e0,
	100: #b3dcb3,
	200: #80c580,
	300: #4dad4d,
	400: #269c26,
	500: $op-secondary-green,
	// #008a00,
	600: #008200,
	700: #007700,
	800: #006d00,
	900: #005a00,
	A100: #8bff8b,
	A200: #58ff58,
	A400: #25ff25,
	A700: #0cff0c,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000
	)
);

$my-accent: (
	50: #f9ece4,
	100: #efcebc,
	200: #e5ae8f,
	300: #da8e62,
	400: #d27540,
	500: $op-white,
	//#ca5d1e,
	600: #c5551a,
	700: #bd4b16,
	800: #b74112,
	900: #ab300a,
	A100: #ffdfd7,
	A200: #ffb5a4,
	A400: #ff8c71,
	A700: #ff7858,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: $op-green,
		//#ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000
	)
);

$my-warn: (
	50: #ffebee,
	100: #ffcdd2,
	200: #ef9a9a,
	300: #e57373,
	400: #ef5350,
	500: $op-red,
	600: #e53935,
	700: #d32f2f,
	800: #c62828,
	900: #b71c1c,
	A100: #ff8a80,
	A200: #ff5252,
	A400: #ff1744,
	A700: #d50000,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000
	)
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$oportun-primary: mat.define-palette($my-primary);
$oportun-accent: mat.define-palette($my-accent);

// The warn palette is optional (defaults to red).
$oportun-warn: mat.define-palette($my-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.

// prettier-ignore
// $op-commons-pro: mat.define-typography-config(
// 	$font-family: 'TT Commons Pro, Roboto, sans-serif',
// 	$display-1: mat.define-typography-level(62px, 77px, 700),		// ... Display-1
// 	$headline: mat.define-typography-level(44px, 55px, 700), 		// 3xl Display-2
// 	$title: mat.define-typography-level(31px, 39px, 700),				// 2xl Display-3
// 	$subheading-2: mat.define-typography-level(27px, 34px, 700),	// xl  Header-1
// 	$subheading-1: mat.define-typography-level(24px, 30px, 700),	// lg  Header-2
// 	$display-2: mat.define-typography-level(19px, 24px, 700),			// ... Header-3
// 	$display-3: mat.define-typography-level(17px, 22px, 600),			// ... Header-4
// 	$body-1: mat.define-typography-level(17px, 25px, 400),			// md  Body-1
// 	$display-4: mat.define-typography-level(15px, 19px, 400),			// ... Body-2
// 	$body-2: mat.define-typography-level(13px, 13px, 400),			// sm  Label
// 	$button: mat.define-typography-level(17px, 25px, 400),			// lg-btn
// 	$caption: mat.define-typography-level(13px, 18px, 400),			// xs Micro
// 	$input: mat.define-typography-level(inherit, 1.125, 400)
// );

$op-display-1: mat.define-typography-level(62px, 77px, 700, $font-family: 'TT Commons Pro, sans-serif');
$op-display-2: mat.define-typography-level(44px, 55px, 700, $font-family: 'TT Commons Pro, sans-serif');
$op-display-3: mat.define-typography-level(31px, 39px, 700, $font-family: 'TT Commons Pro, sans-serif');
$op-header-1: mat.define-typography-level(27px, 34px, 700, $font-family: 'TT Commons Pro, sans-serif');
$op-header-2: mat.define-typography-level(24px, 30px, 700, $font-family: 'TT Commons Pro, sans-serif');
$op-header-3: mat.define-typography-level(19px, 24px, 700, $font-family: 'TT Commons Pro, sans-serif');
$op-header-4: mat.define-typography-level(17px, 22px, 600, $font-family: 'TT Commons Pro, sans-serif');
$op-body-1: mat.define-typography-level(17px, 25px, 400, $font-family: 'TT Commons Pro, sans-serif');
$op-body-2: mat.define-typography-level(15px, 19px, 400, $font-family: 'TT Commons Pro, sans-serif');
$op-label: mat.define-typography-level(13px, 13px, 400, $font-family: 'TT Commons Pro, sans-serif');
$op-micro: mat.define-typography-level(12px, 18px, 400, $font-family: 'TT Commons Pro, sans-serif');

// prettier-ignore
$op-commons-pro: map.merge(
	mat.define-typography-config(
		$font-family: 'TT Commons Pro, sans-serif',
		$display-1: mat.define-typography-level(62px, 77px, 700),		// ... Display-1
		$headline: mat.define-typography-level(44px, 55px, 700), 		// 3xl Display-2
		$title: mat.define-typography-level(31px, 39px, 700),				// 2xl Display-3
		$subheading-2: mat.define-typography-level(27px, 34px, 700),	// xl  Header-1
		$subheading-1: mat.define-typography-level(24px, 30px, 700),	// lg  Header-2
		$body-1: mat.define-typography-level(17px, 25px, 400),			// md  Body-1
		$body-2: mat.define-typography-level(13px, 13px, 400),			// sm  Label
		$button: mat.define-typography-level(17px, 25px, 600),			// lg-btn
		$caption: mat.define-typography-level(13px, 18px, 400),			// xs Micro
		$input: mat.define-typography-level(inherit, 1.125, 400)
	),
	(
		"op-display-1": $op-display-1,
		"op-display-2": $op-display-2,
		"op-display-3": $op-display-3,
		"op-header-1": $op-header-1,
		"op-header-2": $op-header-2,
		"op-header-3": $op-header-3,
		"op-header-4": $op-header-4,
		"op-body-1": $op-body-1,
		"op-body-2": $op-body-2,
		"op-label": $op-label,
		"op-micro": $op-micro
	)
);

// prettier-ignore
// $op-commons-pro: mat.define-typography-config(
// 	$font-family: 'TT Commons Pro, Roboto, sans-serif',
// 	$display-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),	// 4xl Display-1
// 	$display-3: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),		// 3xl Display-2
// 	$display-2: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),	// 2xl Display-3
// 	$display-1: mat.define-typography-level(34px, 40px, 400),	// xl  Header-1
// 	$headline: mat.define-typography-level(20px, 27px, 500),		// lg  Header-2
// 	$title: mat.define-typography-level(20px, 27px, 400),			// ... Header-3
// 	$subheading-1: mat.define-typography-level(15px, 17px, 400),// ... Header-4
// 	$subheading-2: mat.define-typography-level(16px, 21px, 500),// md  Body-1
// 	$body-1: mat.define-typography-level(16px, 21px, 400),		// ... Body-2
// 	$body-2: mat.define-typography-level(16px, 25px, 500),		// sm  Label
// 	$caption: mat.define-typography-level(14px, 21px, 400),		// xs  Micro
// 	$button: mat.define-typography-level(16px, 21px, 400),
// 	$input: mat.define-typography-level(inherit, 1.125, 400)
// );

// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat.core($op-commons-pro);
@include mat.typography-hierarchy($op-commons-pro);
@include mat.button-typography($op-commons-pro);
@include mat.input-typography($op-commons-pro);

$oportun-theme: mat.define-light-theme(
	(
		color: (
			primary: $oportun-primary,
			accent: $oportun-accent,
			warn: $oportun-warn
		),
		typography: $op-commons-pro,
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($oportun-theme);

// @include mat-checkbox-theme($candy-app-theme);

@include mat.all-component-typographies($op-commons-pro);

@debug 'hello is anyone there.';

@mixin typography($theme) {
	$typography-config: mat.get-typography-config($theme);

	.op-display-1 {
		@include mat.typography-level($typography-config, 'op-display-1');
	}
	.op-display-2 {
		@include mat.typography-level($typography-config, 'op-display-2');
	}
	.op-display-3 {
		@include mat.typography-level($typography-config, 'op-display-3');
	}
	.op-header-1 {
		@include mat.typography-level($typography-config, 'op-header-1');
	}
	.op-header-2 {
		@include mat.typography-level($typography-config, 'op-header-2');
	}
	.op-header-3 {
		@include mat.typography-level($typography-config, 'op-header-3');
	}
	.op-header-4 {
		@include mat.typography-level($typography-config, 'op-header-4');
	}
	.op-body-1 {
		@include mat.typography-level($typography-config, 'op-body-1');
	}
	.op-body-2 {
		@include mat.typography-level($typography-config, 'op-body-2');
	}
	.op-label {
		@include mat.typography-level($typography-config, 'op-label');
	}
	.op-micro {
		@include mat.typography-level($typography-config, 'op-micro');
	}
}
@include typography($oportun-theme);

