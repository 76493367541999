.op-green-bold {
	@extend .op-green;
	font-weight: 600;
}

.op-black-bold {
	@extend .op-primary-black;
	font-weight: 600;
}

.op-grey-bold {
	@extend .op-gray;
	font-weight: 600;
}

.op-display-1-font {
	@extend .op-display-1;
}

.op-display-2-font {
	@extend .op-display-2;
}

.op-display-3-font {
	@extend .op-display-3;
}

.op-header-1-font {
	@extend .op-header-1;
}

.op-header-2-font {
	@extend .op-header-2;
}

.op-header-2-bold-font {
	@extend .op-header-2-font;
	font-weight: 600;
}

.op-icon-align-header-2 {
	@extend .op-header-2-font;
	text-align: left;
	display: grid;
	grid-template-columns: 24px auto;
	grid-gap: 10px;
	.mat-icon {
		margin: auto 0;
		font-size: 24px;
		vertical-align: middle;
	}
}

.op-icon-align-header-2-green {
	@extend .op-icon-align-header-2;
	.mat-icon {
		color: $op-green;
	}
}

.op-header-3-font {
	@extend .op-header-3;
}

.op-header-4-font {
	@extend .op-header-4;
}

.op-body-1-font {
	@extend .op-body-1;
}

.op-body-1-bold-font {
	@extend .op-body-1-font;
	font-weight: 600;
}

.op-icon-align-body-1 {
	@extend .op-body-1-font;
	text-align: left;
	display: grid;
	grid-template-columns: 1.313rem auto;
	grid-gap: 0.625rem;
	.mat-icon {
		font-size: 1.313rem;
		vertical-align: middle;
	}
}

.op-icon-align-body-1-bold {
	@extend .op-icon-align-body-1;
	font-weight: 600;
}

.op-icon-align-body-1-green {
	@extend .op-icon-align-body-1;
	.mat-icon {
		color: $op-green;
	}
}

.op-body-2-font {
	@extend .op-body-2;
}

.op-body-2-bold-font {
	@extend .op-body-2-font;
	font-weight: 600;
}

.op-icon-align-body-2 {
	@extend .op-body-2;
	text-align: left;
	display: grid;
	grid-template-columns: 26px auto;
	grid-gap: 5px;
	.mat-icon {
		margin: auto 0px;
		width: 25px;
		height: 25px;
		vertical-align: middle;
	}
}

.op-icon-align-body-2-green {
	@extend .op-icon-align-body-2;
	.mat-icon {
		color: $op-green;
	}
}

.op-label-font {
	@extend .op-label;
}

.op-label-bold-font {
	@extend .op-label-font;
	font-weight: 600;
}

.op-icon-align-label-font {
	@extend .op-label-font;
	text-align: left;
	display: grid;
	grid-template-columns: 1rem auto;
	grid-gap: 3px;
	.mat-icon {
		font-size: 0.9375rem;
		width: 0.9375rem;
		height: 0.9375rem;
		vertical-align: middle;
	}
}

.op-icon-align-label {
	@extend .op-label-font;
	text-align: left;
	display: grid;
	grid-template-columns: 17px auto;
	grid-gap: 3px;
	.mat-icon {
		font-size: 16px;
		width: 16px;
		height: 16px;
		vertical-align: middle;
	}
}

.op-icon-align-label-green-font {
	@extend .op-icon-align-label;
	.mat-icon {
		color: $op-green;
	}
}

.op-micro-font {
	@extend .op-micro;
}

.op-micro-bold-font {
	@extend .op-micro-font;
	font-weight: 600;
}

.op-micro-alt-font {
	@extend .op-micro-font;
	font-size: 12px;
}

.op-icon-align-micro {
	@extend .op-micro-font;
	text-align: left;
	display: grid;
	grid-template-columns: 14px auto;
	grid-gap: 3px;
	.mat-icon {
		margin: 0 0 auto 0;
		width: 13px;
		height: 13px;
		vertical-align: middle;
	}
}

.op-micro-bold-font {
	@extend .op-micro-font;
	font-weight: 600;
}

.op-bold-ul-green {
	font-weight: 600;
	text-decoration: underline;
	text-decoration-color: $op-green;
}

.op-icon-align-header-1 {
	@extend .op-header-1-font;
	text-align: left;
	display: grid;
	grid-template-columns: 1.5rem auto;
	grid-gap: 0.625rem;
	.mat-icon {
		height: auto;
		width: 24px;
	}
}
