@use '@angular/material' as mat;

.op-button-primary-disabled {
	background-color: $op-light2-grey;
	color: $op-white;
}

.op-button-secondary-disabled {
	background-color: $op-white;
	border: 1.5px solid $op-light2-grey;
	color: $op-disabled-dark-grey;
}

.op-button {
	@include mat.typography-level($op-commons-pro, 'button');
	background-color: $op-primary-black;
	border-color: $op-primary-black;
	color: $op-white;
	white-space: pre-wrap;
	box-sizing: border-box;
	padding: 13.5px 25px;
	gap: 10px;
	min-height: 52px;
	border-radius: 16px;
}

.op-button-small {
	@include mat.typography-level($op-commons-pro, 'button');
	background-color: $op-primary-black;
	border-color: $op-primary-black;
	color: $op-white;
	white-space: pre-wrap;
	box-sizing: border-box;
	padding: 8.5px 16px;
	gap: 10px;
	min-height: 42px;
	border-radius: 12px;
}

.op-button-micro {
	@include mat.typography-level($op-commons-pro, 'op-label');
	background-color: $op-primary-black;
	border-color: $op-primary-black;
	font-weight: 500;
	color: $op-white;
	white-space: pre-wrap;
	box-sizing: border-box;
	padding: 6px 12px;
	gap: 10px;
	min-height: 28px;
	border-radius: 50px;
}

.op-button-secondary {
	@extend .op-button;
	border: 1.5px solid $op-primary-black;
	background-color: $op-white;
	color: $op-black;
}

.op-button-secondary-small {
	@extend .op-button-small;
	border: 1.5px solid $op-primary-black;
	background-color: $op-white;
	color: $op-black;
}

.mat-flat-button.mat-primary-small {
	@extend .op-button-small;
}

.mat-flat-button.mat-primary {
	@extend .op-button;
}

.mat-flat-button.mat-secondary-small {
	@extend .op-button-small;
	border: 1.5px solid $op-primary-black;
	background-color: $op-white;
	color: $op-black;
}

.mat-flat-button.mat-secondary-micro {
	@extend .op-button-micro;
	border: 1.5px solid $op-primary-black;
	background-color: $op-white;
	color: $op-black;
}

.mat-flat-button.mat-secondary {
	@extend .op-button;
	border: 1.5px solid $op-primary-black;
	background-color: $op-white;
	color: $op-black;
}

.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-primary-small.mat-button-disabled {
	@extend .op-button-primary-disabled;
}

.mat-flat-button.mat-secondary.mat-button-disabled,
.mat-flat-button.mat-secondary-small.mat-button-disabled {
	@extend .op-button-secondary-disabled;
}

.mat-flat-button.mat-warn {
	@extend .op-button;
}

.mat-flat-button.mat-blue {
	@extend .op-button;
	color: $op-white;
	background-color: $op-s-blue;
}

.mat-flat-button.mat-black {
	@extend .op-button;
	color: $op-white;
	background-color: $op-charcoal;
}

.mat-flat-button.mat-op-link {
	color: $op-dark-green;
	text-decoration: underline;
	text-underline-position: under;
	svg {
		fill: $op-dark-green;
	}
}

.mat-flat-button.mat-op-link-green {
	color: $op-dark-green;
	svg {
		fill: $op-dark-green;
	}
}

.mat-flat-button.mat-link {
	color: $blue-gray;
	text-decoration: underline;
	text-underline-position: under;
	margin: 0;
	padding: 0;
}

.mat-flat-button.mat-link-bold {
	color: $op-black;
	border-radius: 0;
	padding-left: 0px;
	padding-right: 0px;
	margin-bottom: 3px;
	text-align: left;
	text-transform: none;
	text-decoration: solid underline $op-primary-green 2px;
	text-underline-position: under;
	:hover,
	:active {
		text-decoration: solid underline $op-dark-green 2px;
	}
}

.mat-flat-button.mat-link-md {
	@extend .op-body-1-font;
	color: $op-black;
	border-radius: 0;
	padding-left: 0px;
	padding-right: 0px;
	margin-bottom: 3px;
	text-align: left;
	text-transform: none;
	text-decoration: solid underline $op-primary-green 2px;
	text-underline-position: under;
	:hover,
	:active {
		text-decoration: solid underline $op-dark-green 2px;
	}
}

.mat-flat-button.mat-link-b2 {
	@extend .op-body-2-font;
	color: $op-black;
	border-radius: 0;
	padding-left: 0px;
	padding-right: 0px;
	margin-bottom: 3px;
	text-align: left;
	text-transform: none;
	text-decoration: solid underline $op-primary-green 2px;
	text-underline-position: under;
	:hover,
	:active {
		text-decoration: solid underline $op-dark-green 2px;
	}
}

.op-link-text {
	@include mat.typography-level($op-commons-pro, 'op-body-2');
	font-weight: 600;
	color: #079B33;
	padding: 0px;
	margin: 0px;
	text-align: left;
	text-transform: none;
	text-decoration: none;
	padding: 4px 6px;
	border-radius: 8px;
	border: 1.5px solid transparent;
	:hover {
		color: #3ace66;
	}
	:active {
		color: #9de6b3;
	}
	.mat-ripple {
		display: none;
	}
	.mat-button-focus-overlay {
		background-color: transparent;
	}
}

.op-link-text:focus {
	width: max-content;
	border: 1.5px solid $op-primary-black;
}

.mat-flat-button.mat-link-text {
	@extend .op-link-text;
}

.mat-flat-button.mat-link-text-b1 {
	@extend .op-link-text;
	@include mat.typography-level($op-commons-pro, 'op-body-1');
	font-weight: 600;
}

.mat-flat-button.mat-link-text-h3 {
	@extend .op-link-text;
	@include mat.typography-level($op-commons-pro, 'op-header-3');
}

.mat-flat-button.mat-link-text-micro {
	@extend .op-link-text;
	@include mat.typography-level($op-commons-pro, 'op-micro');
	font-weight: 600;
}

.mat-flat-button.mat-link-no-dec {
	color: $op-black;
}

@media (hover: hover) and (pointer: fine) {
	.mat-flat-button.mat-primary:hover {
		color: $op-white;
		background-color: $op-light-black;
	}
	.mat-flat-button.mat-primary-small:hover {
		color: $op-white;
		background-color: $op-primary-grey;
	}
	.mat-flat-button.mat-secondary:hover {
		color: $op-disabled-dark-grey;
		border-color: $op-disabled-dark-grey;
		background-color: $op-white;
	}

	.mat-flat-button.mat-secondary-small:hover {
		color: $op-disabled-dark-grey;
		border-color: $op-disabled-dark-grey;
		background-color: $op-white;
	}
}

.mat-flat-button.mat-primary:focus {
	@extend .op-button;
	border-radius: 18px;
	position: relative;
	&::before,
	&::after {
		content: '';
		inset: 2px;
		border: 2px solid;
		border-radius: 16px;
		position: absolute;
	}
}

.mat-flat-button.mat-primary:active {
	@extend .op-button;
	background-color: $op-disabled-dark-grey;
}

.mat-flat-button.mat-secondary:active {
	@extend .op-button;
	// background-color: $op-light-grey;
	background-color: yellow;
	border-color: $op-disabled-dark-grey;
}

.mat-flat-button.mat-primary-small:focus {
	@extend .op-button-small;
	border-radius: 14px;
	position: relative;
	&::before,
	&::after {
		content: '';
		inset: 2px;
		border: 2px solid;
		border-radius: 12px;
		position: absolute;
	}
}

.mat-flat-button.mat-primary .mat-button-focus-overlay,
.mat-flat-button.mat-primary-small .mat-button-focus-overlay,
.mat-flat-button.mat-secondary .mat-button-focus-overlay,
.mat-flat-button.mat-secondary-small .mat-button-focus-overlay {
	background-color: transparent;
}

.mat-flat-button.mat-secondary:focus {
	@extend .op-button-secondary;
	border-radius: 18px;
	position: relative;
	background-color: #ffffff;
	&::before,
	&::after {
		content: '';
		inset: 2px;
		border: 1.5px solid;
		// background-color: transparent		;
		border-radius: 16px;
		position: absolute;
	}
}

.mat-flat-button.mat-secondary-small:focus {
	@extend .op-button-secondary-small;
	border-radius: 14px;
	position: relative;
	&::before,
	&::after {
		content: '';
		inset: 2px;
		border: 1.5px solid;
		border-radius: 12px;
		position: absolute;
	}
}

.mat-flat-button.mat-primary.mat-button-disabled:hover,
.mat-flat-button.mat-primary-small.mat-button-disabled:hover {
	@extend .op-button-primary-disabled;
}

.mat-flat-button.mat-secondary.mat-button-disabled:hover,
.mat-flat-button.mat-secondary-small.mat-button-disabled:hover {
	@extend .op-button-secondary-disabled;
}

.mat-icon-button:focus {
	background-color: $op-primary-grey;
}

/* unvisited link */
a:link {
	color: $op-dark-green;
}

/* visited link */
a:visited {
	color: $op-blue-magenta;
}

/* mouse over link */
a:hover {
	color: $op-primary-green;
}

/* selected link */
a:active {
	color: $op-green400;
	text-decoration: none;
}

.mat-flat-button.mat-link-green {
	color: $op-primary-green;
}

.op-rounded-button-small {
	@extend .op-label-font;
	width: 80px;
	height: 20px;
	padding: 4px;
	border-radius: 100px;
	font-weight: 400;
	border: solid 1px $op-green;
	background-color: $op-light-green;
	display: flex;
	justify-content: center;
	align-items: center;
}
