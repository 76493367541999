/**
 * DSM Colors
 * https://www.figma.com/file/l163rGeY8LS2TuW0dSMGix/Master-DSM
 * --------------------------------------------------
 */
 $op-primary-black: #000000;
 $op-primary-grey: #949494;
 $op-disabled-grey: #e8e8e8;
 $op-disabled-dark-grey: #bfbfbf;

 $op-primary-green: #6cdb8b;
 $op-secondary-green: #00c859;
 $op-third-green: #9be5ae;
 $op-fourth-green: #00a542;
 $op-green400: #40d271;
 $op-dark-green: #009337;
 $op-darker-green: #007324;
 $op-light-green: #e6f9eb;
 $op-orange: #ff7300;
 $op-red: #c63724;
 $op-red2: #FC3F2E;
 $op-red2-background: #FFECEA;
 $op-light-blue: #f5faff;
 $op-black: #1a1a1a;
 $op-dark-grey: #6c6c6c;
 $op-medium-grey: #d8d8d8;
  $op-medium2-grey: #f9f9f9;
 $op-light-grey: #f2f2f2;
 $op-light2-grey: #E5E5E5;
 $op-light-black: #595959;

 $op-blue: #219bed;
 $op-blue2: #5CBFEA;
 $op-blue3: #E5F5FB;
 $op-blue4: #009ADC;
 $op-purple: #2f167c;
 $op-violet: #070540;
 $op-blue-magenta: #9c80ff;
 $op-medium-blue: #b9d1e3;
 $op-warning: #ffce20;
 $op-warning-light: #FFF7E6;
 $op-light-warning: #FDF9E3;

 /**
	 * Colors
	 * --------------------------------------------------
	 */
 $op-green: #6cdb8b;
 $op-dark-gray: #444c58; //$charcoal
 $op-white: #ffffff;
 $op-gray: #f2f2f2;
 $op-icon-gray: #b5b5b5;
 $op-charcoal: #3b3b3c;
 $op-d-gray: #555555;
 $op-s-blue: #219bed;
 $op-light-silver: #d8d8d8;
 $op-s-gray: #828282;
 $op-slate: #8490a2;
 $op-cyan-blue: #f5faff;

 $op-light-gray: #cdd2db;
 $op-light-gray2: #dddddd;
 $op-light-gray3: #ededed;
 $op-light-gray4: #dfdfdf;

 $error: #e5628a;
 $emergency-red: #ff0000;
 $op-alert-warning-color: #ee7623;

 $grey: #efefef;
 $op-slate: #8490a2;
 $op-light: #e0e3e8;

 $blue-gray: #464556;
 $charcoal-dark: #222222;
 $light-blue-gray: #7a8b96;
 $spanish-gray: #979797;

 $op-vivid-blue: #018ffe; //$purple

 $op-light-purple: #F0E9FF;
 $op-light-red: #FEE6E1;


 /**
	 * Responsive variables
	 * --------------------------------------------------
	 */

 $op-mobile-width: 425px !default;

 $op-screen-xss: 320px !default; // Small phone
 $op-screen-xs: 480px !default; // Phone
 $op-screen-sm: 768px !default; // Small tablet, Big phone
 $op-screen-md: 992px !default; // Tablet, Landscape phone
 $op-screen-lg: 1200px !default; // Desktop
 $op-screen-xlg: 1400px !default; // Widescreen desktop
 $op-screen-xss-min: $op-screen-xss !default;
 $op-screen-xs-min: $op-screen-xs !default;
 $op-screen-sm-min: $op-screen-sm !default;
 $op-screen-md-min: $op-screen-md !default;
 $op-screen-lg-min: $op-screen-lg !default;

 // So media queries don't overlap
 $op-screen-xxs-max: ($op-screen-xs - 1) !default;
 $op-screen-xs-max: ($op-screen-sm - 1) !default;
 $op-screen-sm-max: ($op-screen-md - 1) !default;
 $op-screen-md-max: ($op-screen-lg - 1) !default;
 $op-screen-lg-max: ($op-screen-xlg - 1) !default;

 $op-mat-dialog-padding: 16px;
