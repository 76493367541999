.op-green {
	color: $op-green;
}

.op-dark-green {
	color: $op-dark-green;
}

.op-gray {
	color: $op-s-gray;
}

.op-light-gray {
	color: $op-light-gray2;
}

.op-black {
	color: $op-black;
}

.op-primary-black {
	color: $op-primary-black;
}

.op-orange {
	color: $op-orange;
}

.op-white {
	color: $op-white;
}

.op-blue {
	color: $op-vivid-blue;
}

.op-dark-grey {
	color: $op-dark-grey;
}

.op-warning {
	color: $op-warning;
}

.op-alert-warning {
	color: $op-red;
}

.op-light-black {
	color: $op-light-black;
}
